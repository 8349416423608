import {
  IProfile,
  IUser,
  SanityBaseLanguage,
} from '@guider-global/shared-types';
import { Text } from '@guider-global/ui';
import { Box } from '@mui/material';
import { MenuItem } from 'components';
import { AvatarUser } from 'components/AvatarUser';
import { Link as RouterLink } from 'react-router-dom';

export interface NavbarMenuProfileCardViewProps {
  onClick: () => void;
  label: string | undefined;
  to: string;
  profile?: IProfile;
  user?: IUser;
  documentDirection?: SanityBaseLanguage['text_direction'];
}

export function NavbarMenuProfileCardView({
  onClick,
  to,
  user,
  profile,
  documentDirection,
}: NavbarMenuProfileCardViewProps) {
  const isDocumentDirectionRtl = documentDirection === 'rtl';
  return (
    <MenuItem
      component={RouterLink}
      to={to}
      action={onClick}
      data-cy="components_Navbar_profile-button"
      muiItemProps={{
        sx: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: isDocumentDirectionRtl ? 2 : undefined,
          pr: !isDocumentDirectionRtl ? 12 : undefined,
          pl: isDocumentDirectionRtl ? 12 : undefined,
        },
      }}
    >
      <AvatarUser
        sx={{
          width: 50,
          height: 50,
          mr: 1,
          borderRadius: '50%',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'column nowrap',
        }}
      >
        <Text text={profile?.displayName} />
        <Text text={user?.email} fontSize={'12px'} color={'text.secondary'} />
      </Box>
    </MenuItem>
  );
}
